.drop-zone {
    position: relative;
    &__prompt {
        &--standart {
            line-height: 40px;
            color: #7f7586;
            font-size: 16px; } }
    &__thumb {
        height: 100%;
        width: 100%;
        &::after {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            font-size: 16px;
            color: #7f7586;
            content: attr(data-label);
            background: #e8f0fe;
            position: absolute;
            left: 0;
            padding-left: 15px;
            box-sizing: border-box; } } }
