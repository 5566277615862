.about {
    padding: 50px 0 90px;
    color: $primary;
    @media screen and (max-width: 768px) {
        padding: 20px 0 90px; }
    &_title {
        font-size: 36px;
        margin-bottom: 30px; }
    &_galery {
        margin: 50px 0; }
    &_persons {
        &_box {
            display: flex;
            flex-wrap: wrap;
            &_item {
                display: flex;
                width: 50%;
                align-items: center;
                margin-bottom: 30px;
                box-sizing: border-box;
                padding-right: 30px;
                @media screen and (max-width: 1400px) {
                    flex-direction: column; }
                @media screen and (max-width: 768px) {
                    width: 100%;
                    padding-right: 0; }
                &_image {
                    margin-right: 30px;
                    @media screen and (max-width: 1400px) {
                        margin: 0 auto 30px; }
                    img {
                        display: block; } }
                &_info {
                    @media screen and (max-width: 1400px) {
                        text-align: center; }
                    &_name {
                        font-size: 30px; }
                    &_role {
                        font-size: 23px;
                        margin: 5px 0 30px; }
                    &_email {
                        font-size: 24px;
                        &_link {
                            color: $primary;
                            text-decoration: none; } } } } } }
    &_slider {
        &_title {
            @media screen and (max-width: 768px) {
                margin-top: 5px; } }
        &_box {
            position: relative;
            .owl-dots {
                position: absolute;
                bottom: 20px;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                .owl-dot {
                    border: 1px solid #FFFFFF;
                    width: 13px;
                    height: 13px;
                    border-radius: 100%;
                    margin: 0 3px;
                    outline: 0;
                    display: inline-block;
                    vertical-align: middle;
                    &.active {
                        outline: 0;
                        transition: 0.1s ease-in-out;
                        width: 17px;
                        height: 17px;
                        background: #FFF500;
                        border: 0; }
                    &:active {
                        outline: 0; } } }
            &_item {
                @media screen and (max-width: 610px) {
                    height: 400px !important; }
                @media screen and (max-width: 580px) {
                    height: 500px !important; }
                @media screen and (max-width: 410px) {
                    height: 600px !important; }
                &_block {
                    position: relative;
                    &_image {
                        img {
                            display: block; } }
                    &_area {
                        position: absolute;
                        height: 346px;
                        width: 620px;
                        box-sizing: border-box;
                        padding: 58px 65px 0 50px;
                        background: #FFF500;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        clip-path: polygon(0 0, 100% 0, 87% 100%, 0% 100%);
                        @media screen and (max-width: 610px) {
                            height: 400px;
                            top: 0;
                            transform: none;
                            width: 100%;
                            clip-path: none;
                            padding-right: 10px; }
                        @media screen and (max-width: 580px) {
                            height: 500px; }
                        @media screen and (max-width: 410px) {
                            padding: 20px 25px 0 25px;
                            height: 600px; }
                        &_title {
                            font-size: 30px;
                            margin: 0;
                            padding: 0; }
                        &_text {
                            font-size: 20px;
                            line-height: 25px;
                            margin: 20px 0 30px;
                            p {
                                margin: 0;
                                padding: 0; }
                            a {
                                color: $primary; } }
                        &_btn {
                            &_link {
                                font-size: 0;
                                text-decoration: none;
                                color: $primary;
                                display: block;
                                box-sizing: border-box;
                                display: flex;
                                justify-content: space-between;
                                img {
                                    width: auto !important;
                                    margin-right: 34px; }
                                span {
                                    font-size: 20px; } } } } } } } } }
