.spin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1300px) {
        flex-direction: column; }
    &__row {
        width: 50%;
        @media screen and (max-width: 1300px) {
            width: 100%; } } }
