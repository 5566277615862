.order-box {
    margin-bottom: 50px;
    &__top {
        display: flex;
        background: #FFF500;
        padding: 25px 40px 30px;
        box-sizing: border-box;
        color: #343434;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 575px) {
            flex-direction: column;
            padding: 15px; } }
    &__actions {
        display: flex;
        align-items: center;
        @media screen and (max-width: 575px) {
            margin-top: 15px;
            flex-direction: column; } }
    &__date {
        font-size: 18px; }
    &__cost {
        font-size: 18px;
        padding: 0 20px 0 60px;
        @media screen and (max-width: 575px) {
            padding: 15px 0; } }
    &__repeat {
        padding: 0 40px 0 0;
        @media screen and (max-width: 575px) {
            padding: 0 0 15px 0; } }
    &__btn {
        width: 170px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #FFFFFF;
        cursor: pointer;
        display: block; }
    &__arrow {
        &-btn {
            font-size: 0;
            cursor: pointer;
            img {
                transition: transform 0.3s ease-in-out; }
            &.active {
                img {
                    transform: rotate(-90deg); } } } }
    &__content {
        display: none;
        table {
            display: table !important; } } }
