.spteam {
    padding: 50px 0 150px;
    &_title {
        margin: 0 0 50px 0;
        padding: 0;
        font-size: 36px;
        position: relative;
        max-width: 800px;
        span {
            position: relative;
            z-index: 2; }
        &::before {
            content: '';
            position: absolute;
            width: 214px;
            height: 130%;
            left: 0;
            top: -15px;
            background: #FFF500;
            display: block;
            z-index: 1;
            @media screen and (max-width: 575px) {
                display: none; } } }
    &_info {
        font-size: 30px;
        margin-bottom: 30px; }
    &_slider {
        position: relative;
        .slider-prev-btn, .slider-next-btn {
            @media screen and (min-width: 1360px) {
                top: 325px;
                transform: none; } }
        &_box {
            &_item {
                @media screen and (min-width: 1360px) {
                    padding-top: 170px; }
                &_image {
                    img {
                        display: block; } }
                &_name {
                    margin: 40px 0 30px;
                    font-size: 30px; }
                &_inner {
                    @media screen and (min-width: 1360px) {
                        display: none; } }
                &_list {
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    &_element {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;
                        font-size: 24px;
                        &:last-child {
                            margin-bottom: 0; }
                        &_dot {
                            display: block;
                            flex-grow: 0;
                            flex-shrink: 0;
                            width: 47px;
                            flex-basis: 47px;
                            height: 52px;
                            background: #FFF500;
                            margin-right: 15px; } } } } } }
    &_connect {
        &_link {
            cursor: pointer;
            display: block;
            height: 50px;
            line-height: 50px;
            text-align: center;
            background: #FFF500;
            font-size: 20px;
            margin-top: 60px; } } }

.centerActiveItem {
    @media screen and (min-width: 1360px) {
        width: 652px !important;
        position: relative;
        z-index: 2;
        margin-left: -138px;
        margin-right: -137px; }
    .item {
        @media screen and (min-width: 1360px) {
            padding-top: 0; } }
    .spteam_slider_box_item_inner {
        @media screen and (min-width: 1360px) {
            display: block; } } }
