.delivery-payment {
    padding-top: 40px;
    padding-bottom: 160px;
    color: $primary;
    &_title {
        font-size: 26px;
        margin: 0;
        padding: 0;
        font-family: $bold-font; }
    &_text {
        padding: 40px 0;
        font-size: 24px;
        p {
            margin: 0;
            padding: 0; } }
    &_box {
        display: flex;
        @media screen and (max-width: 1150px) {
            flex-direction: column; }
        &_item {
            border: 1px solid #D0D0D0;
            box-sizing: border-box;
            margin: 0 10px;
            padding: 30px 40px 40px;
            @media screen and (max-width: 1150px) {
                margin: 10px 0; }
            @media screen and (max-width: 480px) {
                padding: 35px 20px 20px; }
            &:last-child {
                margin-right: 0; }
            &:first-child {
                margin-left: 0; }
            &_title {
                font-size: 24px;
                line-height: 24px; }
            &_list {
                list-style: none;
                padding: 0;
                margin: 30px 0 0;
                font-size: 18px;
                line-height: 24px;
                &_element {
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between; } }
            &_info {
                font-size: 18px;
                line-height: 24px;
                p {
                    margin-bottom: 0;
                    padding-bottom: 0; } } } } }
