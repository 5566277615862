.partnership {
    padding: 50px 0 134px;
    &_title {
        font-size: 36px;
        margin: 0 0 30px 0;
        padding: 0; }
    &_vacancies {
        &_item {
            border: 1px solid #D0D0D0;
            display: flex;
            justify-content: space-between;
            padding: 20px 35px;
            @media screen and (max-width: 575px) {
                flex-direction: column; }
            @media screen and (max-width: 400px) {
                padding: 20px 15px; }
            &_info {
                font-size: 18px;
                &_title {
                    margin-bottom: 20px; }
                &_description {
                    p {
                        padding: 0;
                        margin: 0; } } }
            &_action {
                @media screen and (max-width: 575px) {
                    margin-top: 25px; }
                &_salary {
                    font-size: 18px;
                    margin-bottom: 20px;
                    text-align: right;
                    @media screen and (max-width: 575px) {
                        text-align: left; } }
                &_btn {
                    &_link {
                        text-decoration: none;
                        color: $primary;
                        display: inline-block;
                        min-width: 220px;
                        height: 40px;
                        line-height: 40px;
                        text-align: center;
                        background: #FFF500;
                        cursor: pointer;
                        @media screen and (max-width: 575px) {
                            display: block; } } } } } }

    &_clients {
        border: 1px solid #D0D0D0;
        padding: 20px 35px 50px;
        box-sizing: border-box;
        font-size: 18px;
        @media screen and (max-width: 400px) {
            padding: 20px 15px 50px; }
        &_title {
            margin-bottom: 30px; }
        form {
            .form-group {
                margin: 0 auto 10px;
                max-width: 500px;
                width: 100%;
                input {
                    background: #E7E7E7;
                    box-sizing: border-box;
                    border: 0;
                    outline: 0;
                    height: 40px;
                    width: 100%;
                    font-size: 16px;
                    height: 40px;
                    padding: 0 15px;
                    &:active {
                        outline: 0; }
                    ::placeholder {
                        color: #B2B2B2; }
                    -ms-input-placeholder: {
                        color: #B2B2B2; }
                    ::-ms-input-placeholder {
                        color: #B2B2B2; } } }
            .submit {
                box-sizing: border-box;
                margin: 20px auto 0;
                display: block;
                width: 100%;
                max-width: 500px;
                height: 50px;
                text-align: center;
                line-height: 50px;
                border: 0;
                border-radius: 0;
                background: #FFF500;
                font-size: 18px;
                cursor: pointer; } } }
    &_supplier {
        padding: 20px 35px 50px;
        border: 1px solid #D0D0D0;
        box-sizing: border-box;
        @media screen and (max-width: 400px) {
            padding: 20px 15px 50px; }
        &_title {
            font-size: 18px;
            margin-bottom: 30px; }
        &_space {
            &_item {
                border: 1px solid #D0D0D0;
                box-sizing: border-box;
                margin-bottom: 20px;
                display: flex;
                @media screen and (max-width: 575px) {
                    flex-direction: column; }
                &:last-child {
                    margin-bottom: 0; }
                &_image {
                    margin-right: 25px;
                    @media screen and (max-width: 575px) {
                        margin-right: 0;
                        margin-bottom: 25px; }
                    img {
                        display: block;
                        max-width: 166px;
                        height: auto;
                        @media screen and (max-width: 575px) {
                            margin: 0 auto; } } }
                &_description {
                    display: flex;
                    flex-direction: column;
                    box-sizing: border-box;
                    @media screen and (max-width: 575px) {
                        padding: 0 15px;
                        text-align: center; }
                    @media screen and (max-width: 400px) {
                        padding: 0; }
                    &_title {
                        font-size: 16px;
                        margin: 20px 0 0 0;
                        padding: 0; }
                    &_manufacturer {
                        font-size: 16px;
                        margin-top: 15px;
                        margin-bottom: 15px; }
                    &_btn {
                        margin-top: auto;
                        width: 220px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        font-size: 14px;
                        background: #FFF500;
                        margin-bottom: 20px;
                        cursor: pointer;
                        &:focus {
                            outline: 0; }
                        @media screen and (max-width: 575px) {
                            margin: auto auto 20px; } } } } } }
    &_box {
        &_item {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0; }
            &_top {
                background: #FFF500;
                height: 46px;
                line-height: 46px;
                box-sizing: border-box;
                padding: 0 35px;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                img {
                    display: block;
                    transition: 0.3s all ease-in-out; }
                &.active {
                    img {
                        transform: rotate(180deg); } } }
            &_btn {
                width: 100%;
                max-width: 268px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                background: #FFF500;
                margin-bottom: 20px;
                font-size: 14px;
                text-decoration: none;
                color: $primary;
                display: block; }
            &_info {
                border: 1px solid #D0D0D0;
                padding: 20px 35px;
                box-sizing: border-box;
                font-size: 16px;
                @media screen and (max-width: 400px) {
                    padding: 20px 15px; }
                a {
                    text-decoration: none;
                    color: $primary; }
                &.partnership-large-text {
                    font-size: 18px; }
                p {
                    padding: 0;
                    margin: 0; } }
            &_inner {
                display: none; } } } }
