@import '../../node_modules/owl.carousel/dist/assets/owl.carousel.css';
@import '../../node_modules/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
@import 'promotion/styles';
@import 'articles/styles';
@import 'slider/styles';
@import 'delivery-payment/styles';
@import 'partnership/styles';
@import 'about/styles';
@import 'spteam/styles';
@import 'modal-box/styles';
@import 'buyinfo/styles';
@import 'success-payment/styles';
@import 'order-box/styles';
@import 'spin/styles';
@import 'drop-zone/styles';
@import 'tops-slider/styles';


.text-bold {
    font-family: $bold-font
}

.custom-tooltip {
    position: absolute;
    padding: 15px;
    z-index: 99999;
    display: none;
    width: 100%;
    max-width: 340px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    font-size: 13px;
    color: #000;
    line-height: 13px;
    pointer-events: none;
}

.main-container {
    padding-left: 74px;
    padding-right: 154px;
    max-width: 1360px;
    box-sizing: border-box;
    margin: 0 auto;
    @media screen and (max-width: 1440px) {
        padding-right: 74px;
    }
    @media screen and (max-width: 1240px) {
        padding-right: 35px;
        padding-left: 35px;
    }
    @media screen and (max-width: 480px) {
        padding-right: 30px;
        padding-left: 30px;
    }
}
