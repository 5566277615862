.tops-slider {
    max-width: 1150px;
    margin: 15px auto;
    &__box {
        &-item {
            overflow: hidden; } }
    &-left, &-right {
        top: 110px;
        img {
            margin-left: 0 !important; } }
    &-mobile {
        display: flex;
        flex-wrap: wrap; } }
