.buyinfo {
    padding: 20px 0 80px;
    &_title {
        height: 40px;
        background: #FFF500;
        line-height: 40px;
        font-size: 18px;
        text-align: center;
        margin-bottom: 30px; }
    &_box {
        display: flex;
        @media screen and (max-width: 768px) {
            flex-direction: column; }
        &_map {
            width: calc(100% - 315px);
            @media screen and (max-width: 768px) {
                width: 100%; }
            &_block {
                min-height: 500px; } }
        &_action {
            width: 285px;
            flex-basis: 285px;
            margin-right: 30px;
            box-sizing: border-box;
            @media screen and (max-width: 768px) {
                width: 100%;
                flex-basis: 100%;
                margin-right: 0;
                margin-bottom: 30px; }
            &_city {
                padding-right: 15px;
                &_title {
                    font-size: 16px; }
                &_select {
                    margin: 15px 0 20px;
                    position: relative;
                    &_arrow {
                        position: absolute;
                        top: 50%;
                        margin-top: -6px;
                        right: 17px;
                        pointer-events: none; }
                    &_element {
                        width: 100%;
                        height: 40px;
                        font-size: 14px;
                        padding: 0 44px 0 12px;
                        box-sizing: border-box;
                        border: 1px solid #D0D0D0;
                        background: #fff;
                        outline: 0;
                        -moz-appearance: none;
                        -webkit-appearance: none;
                        appearance: none;
                        &:active {
                            outline: 0; } } } }
            &_address {
                &_inner {
                    max-height: 900px;
                    @media screen and (max-width: 768px) {
                        max-height: 270px; }
                    .mCSB_scrollTools {
                        width: 5px;
                        background: #999999; }
                    .mCSB_inside > .mCSB_container {
                        margin-right: 15px; }
                    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
                        width: 5px;
                        background: #FFF500; }
                    .mCSB_scrollTools .mCSB_draggerRail {
                        width: 5px;
                        background: #999999; } }
                &_item {
                    border: 1px solid #D0D0D0;
                    box-sizing: border-box;
                    padding: 12px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    transition: 0.3s background ease-in-out;
                    &.active {
                        background: #FFF500;
                        border: 1px solid #FFF500; }
                    &_name {
                        font-size: 16px;
                        margin-bottom: 8px;
                        display: block; }
                    &_info {
                        font-size: 14px;
                        display: block; }
                    &_link {
                        margin-top: 5px;
                        font-size: 14px;
                        display: block; } } } } } }
