.modal-box {
    background: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    display: none;
    &_inner {
        display: flex;
        align-items: center;
        height: 100%;
        &_block {
            max-height: 100%;
            overflow-y: auto;
            margin: 0 auto;
            max-width: 580px;
            box-sizing: border-box;
            padding: 30px 40px 40px;
            background: #ffffff;
            &_slider {
                max-width: 640px;
                padding: 0;
                background: transparent;
                padding: 0 30px;
                .owl-dots {
                    bottom: -20px;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    .owl-dot {
                        border: 1px solid #FFFFFF;
                        width: 13px;
                        height: 13px;
                        border-radius: 100%;
                        margin: 0 3px;
                        outline: 0;
                        display: inline-block;
                        vertical-align: middle;
                        &.active {
                            outline: 0;
                            transition: 0.1s ease-in-out;
                            width: 17px;
                            height: 17px;
                            background: #FFF500;
                            border: 0; }
                        &:active {
                            outline: 0; } } }
                .item {
                    text-align: center;
                    img {
                        display: block;
                        width: 100%; } } }
            &_large {
                max-width: 750px;
                @media screen and (max-width: 575px) {
                    padding: 30px 15px 40px; } }
            &_title {
                font-size: 18px; }
            &_btn {
                width: 240px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                display: block;
                cursor: pointer;
                text-decoration: none;
                font-size: 18px;
                &_black {
                    background: $primary;
                    color: #fff; }
                &_yellow {
                    background: #FFF500;
                    color: $primary; } }
            &_actions {
                display: flex;
                margin-top: 30px;
                @media screen and (max-width: 580px) {
                    flex-direction: column; }
                &_item {
                    @media screen and (min-width: 580px) {
                        margin-right: 35px; }
                    @media screen and (max-width: 580px) {
                        margin-bottom: 20px; }
                    &:last-child {
                        margin-right: 0;
                        margin-bottom: 0; } } }
            &_form {
                margin-top: 30px;
                &_row {
                    margin-bottom: 10px; }
                &_submit {
                    margin-top: 20px; }
                &_input, &_textarea {
                    background: #E7E7E7;
                    height: 40px;
                    border: 0;
                    outline: 0;
                    padding:  0 15px;
                    display: block;
                    width: 100%;
                    box-sizing: border-box;
                    font-size: 16px;
                    &:active {
                        outline: 0; }
                    ::placeholder {
                        color: #B2B2B2; }
                    -ms-input-placeholder: {
                        color: #B2B2B2; }
                    ::-ms-input-placeholder {
                        color: #B2B2B2; } }
                &_textarea {
                    height: auto;
                    min-height: 100px;
                    padding-top: 15px; }
                &_btn {
                    box-sizing: border-box;
                    display: block;
                    width: 100%;
                    height: 50px;
                    text-align: center;
                    line-height: 50px;
                    border: 0;
                    border-radius: 0;
                    background: #FFF500;
                    font-size: 18px;
                    cursor: pointer; } } } } }
